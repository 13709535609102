import { TComponentProps } from "../../types";
import { EditEmbedYoutubeModal } from "./edit-embed-youtube";

export type TEmbedYoutubeData = {
  src: string;
  title: string;
};

export function EmbedYoutube(props: TComponentProps<TEmbedYoutubeData>) {
  const { editOpened, setEditOpened, setComponent, component } = props;
  return (
    <>
      {setEditOpened ? (
        <EditEmbedYoutubeModal
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div className="flex justify-center">
        <iframe
          src={component.data.src}
          title={component.data.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          className="w-[560px] h-[315px]"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

EmbedYoutube.type = "leaf";
EmbedYoutube.defaultData = {
  src: "",
  title: "YouTube Video",
};
