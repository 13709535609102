/* Layout */
import {
  ComponentWithExtraAttribute,
  TComponentMap,
} from "~/features/pagebuilder/types";
import { DefaultLayoutComponent } from "./layout/default-layout-component";
import { GridLayout2ColsComponent } from "./layout/grid-layout-component";
import SectionComponent from "./layout/section-component";

/* Components */
import { ImageComponent, RichTextComponent } from "~/features/pagebuilder";
import { GridLayoutEqualColsComponent } from "./layout/grid-layout-equal";
import { ButtonComponent } from "./leaf/button-component";
import { EmbedYoutube } from "./leaf/embed-youtube";
import { FeatureCardComponent } from "./leaf/feature-card-component";
import { FormComponent } from "./leaf/form-component";
import { SimpleCardComponent } from "./leaf/simple-card-component";

export const componentMap: TComponentMap = new Map<
  string,
  ComponentWithExtraAttribute<any>
>();

/* Layout */
componentMap.set("section", SectionComponent);
componentMap.set("grid2cols", GridLayout2ColsComponent);
componentMap.set("defaultLayout", DefaultLayoutComponent);
componentMap.set("gridLayoutEqualCols", GridLayoutEqualColsComponent);

/* Components */
componentMap.set("richText", RichTextComponent);
componentMap.set("image", ImageComponent);
componentMap.set("button", ButtonComponent);
componentMap.set("featureCard", FeatureCardComponent);
componentMap.set("embedYoutube", EmbedYoutube);
componentMap.set("simpleCard", SimpleCardComponent);
componentMap.set("formComponent", FormComponent);
