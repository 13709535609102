import { TComponentProps } from "~/features/pagebuilder";
import { EditImageModal } from "./edit-image-component";

export type TImageComponentData = {
  src: string;
  alt: string;
  aspectRatio?: number;
};

export function ImageComponent(props: TComponentProps<TImageComponentData>) {
  const { editOpened, setEditOpened, setComponent, component } = props;
  return (
    <>
      {setEditOpened ? (
        <EditImageModal
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div
        style={{
          aspectRatio: component.data.aspectRatio
            ? `1/${1 / component.data.aspectRatio}`
            : "1/1",
        }}
      >
        <img src={component.data.src} alt={component.data.alt} />
      </div>
    </>
  );
}

ImageComponent.type = "leaf";
ImageComponent.defaultData = {
  src: "",
  alt: "placeholder text",
};
