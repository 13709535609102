"use client";

import { Loading } from "~/components/ui/loading";
import { useGetFormByToken } from "~/features/formbuilder/api/formbuilder-hooks";
import FormRender from "~/features/formbuilder/components/form-render";
import { cn } from "~/lib/utils";

export function FormComponentClient({
  token,
  center,
}: {
  token: string;
  center?: boolean;
}) {
  const { data } = useGetFormByToken(token);
  return (
    <>
      {token === "" && <span className="font-semibold">No form selected</span>}
      {data ? (
        <div className={cn("w-full max-w-[600px]", center && "mx-auto")}>
          <FormRender form={data} token={token} hideLogo />
        </div>
      ) : token !== "" ? (
        <Loading />
      ) : null}
    </>
  );
}
