"use client";

import { useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/components/ui/alert-dialog";
import { Button } from "~/components/ui/button";
import { TComponentMap } from "../types";

type TAddComponentModalProps = {
  opened: boolean;
  close: () => void;
  addComponent: any;
  componentType: string;
  componentMap: TComponentMap;
};

export default function AddComponentModal(props: TAddComponentModalProps) {
  const { opened, close, addComponent, componentType, componentMap } = props;
  const options: string[] = useMemo(() => {
    return Array.from(componentMap.entries())
      .filter(([key, value]) => value.type === componentType)
      .map(([key]) => key);
  }, [componentType]);
  const addSelectedComponent = useCallback(
    (componentName: string) => {
      const Component = componentMap.get(componentName);
      if (!Component) return;
      addComponent({
        componentName: componentName,
        data: Component.defaultData ? Component.defaultData : {},
        id: uuidv4(),
      });
      close();
    },
    [addComponent, close]
  );
  useEffect(() => {
    if (opened && options.length === 1) {
      addSelectedComponent(options[0]);
    }
  }, [opened, addSelectedComponent, options]);

  return (
    <AlertDialog open={opened}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Select component</AlertDialogTitle>
        </AlertDialogHeader>
        {options.map((option) => (
          <Button
            onClick={() => {
              addSelectedComponent(option);
            }}
            key={option}
          >
            {" "}
            {option}
          </Button>
        ))}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={close}>Cancel</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
