"use client";

import { JSONContent } from "@tiptap/react";
import { useEffect, useMemo, useState } from "react";
import TipTap from "~/components/text-editor/tiptap";
import { TComponent } from "../../types";
import { EditModal } from "../edit-modal";
import { TSimpleCardComponentData } from "./simple-card-component";

export function EditSimpleCardComponent(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TSimpleCardComponentData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [state, setState] = useState(component.data);
  useEffect(() => {
    // this is impportant, otherwise we will overwrite the component with old state on save
    setState(component.data);
  }, [component]);

  const jsonContent = useMemo(() => {
    try {
      return JSON.parse(state.content);
    } catch (e) {
      return {} as JSONContent;
    }
  }, [state.content]);

  if (!setComponent) {
    return null;
  }

  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      title="Edit Button"
      onSave={() => {
        setComponent({
          ...component,
          data: state,
        });
      }}
    >
      <div>
        <TipTap
          content={jsonContent}
          setContent={(newJsonContent) =>
            setState({ ...state, content: JSON.stringify(newJsonContent) })
          }
          contentClassName="richTextContent smaller"
        />
      </div>
    </EditModal>
  );
}
