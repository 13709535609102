"use client";

import { produce } from "immer";
import { useEffect, useState } from "react";
import { Checkbox } from "~/components/ui/checkbox";
import { Slider } from "~/components/ui/slider";
import Text from "~/components/ui/text";

import { EditModal } from "~/features/pagebuilder";
import { TComponent } from "~/features/pagebuilder/types";
import { TGridLayout2ColsData } from "./grid-layout-component";

export function EditGridLayout2Cols(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TGridLayout2ColsData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [state, setState] = useState(component.data);

  useEffect(() => {
    // this is impportant, otherwise we will overwrite the component with old state on save
    setState(component.data);
  }, [component]);

  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      title="Edit Layout"
      onSave={() => {
        setComponent(
          produce(component, (draft) => {
            draft.data = state;
          })
        );
      }}
    >
      <div className="mb-8">
        <Text>Column ratio</Text>

        <Slider
          className="mt-2"
          min={1}
          max={4}
          step={1}
          value={[state.ratio]}
          onValueChange={(value) => {
            setState(
              produce(state, (draft) => {
                draft.ratio = value[0];
              })
            );
          }}
        />
      </div>
      <div className="items-top flex space-x-2 my-4">
        <Checkbox
          checked={state.reverseMobileOrder}
          id={`reverse-mobile-order-${component.id}`}
          onCheckedChange={(checked) => {
            setState(
              produce(state, (draft) => {
                draft.reverseMobileOrder = !!checked.valueOf();
              })
            );
          }}
        />
        <div className="grid gap-1.5 leading-none">
          <label
            htmlFor="{`reverse-mobile-order-${component.id}`}"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Reverse order on mobile?
          </label>
        </div>
      </div>
    </EditModal>
  );
}
