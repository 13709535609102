import { TComponent, TComponentMap } from "../types";
import styles from "./render.module.css";

function RenderComponentView({
  component,
  componentMap,
}: {
  component: TComponent;
  componentMap: TComponentMap;
}) {
  const Component = componentMap.get(component.componentName);

  if (!Component) {
    console.warn(`Component ${component.componentName} not found`);
    return null;
  }

  return (
    <div className={`relative ${styles.componentWrapper} `}>
      <Component
        component={component}
        editOpened={false}
        componentMap={componentMap}
      />
    </div>
  );
}
export function RenderComponentsView({
  components,
  componentMap,
}: {
  components: TComponent[];
  componentMap: TComponentMap;
}) {
  return (
    <div>
      {components.map((component, index) => (
        <RenderComponentView
          key={index}
          component={component}
          componentMap={componentMap}
        />
      ))}
    </div>
  );
}
