import { produce } from "immer";

import { RenderComponents } from "~/features/pagebuilder";
import { TComponent, TComponentProps } from "~/features/pagebuilder/types";
import { EditGridLayout2Cols } from "./edit-grid-layout-component";

export type TGridLayout2ColsData = {
  col1: TComponent[];
  col2: TComponent[];
  reverseMobileOrder?: boolean;
  ratio: number;
};
export function GridLayout2ColsComponent(
  props: TComponentProps<TGridLayout2ColsData>
) {
  const { editOpened, setEditOpened, setComponent, component, componentMap } =
    props;

  const colSpanMap = [
    "md:col-span-2",
    "md:col-span-4",
    "md:col-span-6",
    "md:col-span-8",
    "md:col-span-10",
  ];
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 px-1">
      {setEditOpened ? (
        <EditGridLayout2Cols
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div
        className={`py-1  ${colSpanMap[component.data.ratio]} ${
          component.data.reverseMobileOrder ? "order-2" : "order-1"
        } md:order-1`}
      >
        {
          <RenderComponents
            components={component.data.col1}
            componentMap={componentMap}
            setComponents={
              setComponent
                ? (newComponents: TComponent[]) =>
                    setComponent(
                      produce(component, (draft) => {
                        draft.data.col1 = newComponents;
                      })
                    )
                : undefined
            }
            parentComponent={component}
          />
        }
      </div>
      <div
        className={`py-2 ${colSpanMap[4 - component.data.ratio]}  ${
          component.data.reverseMobileOrder ? "order-1" : "order-2"
        } md:order-2`}
      >
        {
          <RenderComponents
            components={component.data.col2}
            componentMap={componentMap}
            setComponents={
              setComponent
                ? (newComponents: TComponent[]) =>
                    setComponent(
                      produce(component, (draft) => {
                        draft.data.col2 = newComponents;
                      })
                    )
                : undefined
            }
            parentComponent={component}
          />
        }
      </div>
    </div>
  );
}

GridLayout2ColsComponent.defaultData = {
  col1: [],
  col2: [],
  ratio: 2,
  reverseMobileOrder: false,
};
GridLayout2ColsComponent.type = "layout";
