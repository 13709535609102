"use client";

import { produce } from "immer";
import { useEffect, useState } from "react";
import { Slider } from "~/components/ui/slider";

import { FormRowDepricated } from "~/components/ui/form_old";
import { EditModal } from "~/features/pagebuilder";
import { TComponent } from "~/features/pagebuilder/types";
import { TGridLayoutEqualColsData } from "./grid-layout-equal";

export function EditGridLayoutEqualCols(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TGridLayoutEqualColsData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [state, setState] = useState(component.data);

  useEffect(() => {
    // this is impportant, otherwise we will overwrite the component with old state on save
    setState(component.data);
  }, [component]);

  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      title="Edit Layout"
      onSave={() => {
        setComponent(
          produce(component, (draft) => {
            draft.data = state;
          })
        );
      }}
    >
      <FormRowDepricated
        label="Columns"
        input={
          <div className="w-[300px] flex flex-col justify-center items-center">
            <Slider
              className="mt-2"
              min={1}
              max={5}
              step={1}
              value={[state.col_count]}
              onValueChange={(value) => {
                setState(
                  produce(state, (draft) => {
                    draft.col_count = value[0];
                  })
                );
              }}
            />
            <div className="text-xl">{state.col_count}</div>
          </div>
        }
      />
    </EditModal>
  );
}
