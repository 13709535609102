import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";
import { TComponentProps } from "../../types";
import { EditButtonModal } from "./edit-button-component";

export type TButtonComponentData = {
  label: string;
  href: string;
  color: string;
  alignment: string;
  new_window?: boolean;
};

export function ButtonComponent(props: TComponentProps<TButtonComponentData>) {
  const { editOpened, setEditOpened, setComponent, component } = props;
  return (
    <>
      {setEditOpened ? (
        <EditButtonModal
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div
        className={cn(
          "flex py-2",
          component.data.alignment == "center" ? "justify-center" : "",
          component.data.alignment == "right" ? "justify-end" : ""
        )}
      >
        <Link
          to={component.data.href}
          target={component.data.new_window ? "_blank" : undefined}
          rel="noreferrer"
        >
          <Button size={"xl"}>{component.data.label}</Button>
        </Link>
      </div>
    </>
  );
}

ButtonComponent.type = "leaf";
ButtonComponent.defaultData = {
  label: "Button",
  href: "/",
  color: "primary",
  alignment: "left",
};
