"use client";

import { produce } from "immer";
import { useState } from "react";
import { PlusButton } from "~/components/ui/icon-button";
import { TComponent, TComponentMap } from "../types";
import AddComponentModal from "./add-component-modal";
import { EditOverlay } from "./edit-overlay";
import styles from "./render.module.css";

function RenderComponentEdit({
  component,
  setComponent,
  addComponent,
  componentMap,
}: {
  component: TComponent;
  setComponent?: (newComponent: TComponent | null) => void;
  addComponent?: (newComponent: TComponent) => void;
  componentMap: TComponentMap;
}) {
  const Component = componentMap.get(component.componentName);
  const [addComponentOpened, setAddComponentOpened] = useState(false);
  const [editOpened, setEditOpened] = useState(false);

  if (!Component) {
    console.warn(`Component ${component.componentName} not found`);
    return null;
  }

  return (
    <div className={`relative ${styles.componentWrapper} `}>
      <Component
        componentMap={componentMap}
        component={component}
        setComponent={setComponent}
        editOpened={editOpened}
        setEditOpened={setEditOpened}
      />
      {setComponent ? (
        <EditOverlay
          componentType={Component.type}
          setAddComponentOpened={setAddComponentOpened}
          openEdit={() => setEditOpened(true)}
          setComponent={setComponent}
          component={component}
        />
      ) : null}

      <AddComponentModal
        componentMap={componentMap}
        opened={addComponentOpened}
        close={() => setAddComponentOpened(false)}
        componentType={Component.type}
        addComponent={addComponent}
      />
    </div>
  );
}
export function RenderComponentsEdit({
  components,
  setComponents,
  parentComponent,
  componentMap,
}: {
  components: TComponent[];
  setComponents?: (newComponents: TComponent[]) => void;
  parentComponent?: TComponent;
  componentMap: TComponentMap;
}) {
  const [addComponentOpened, setAddComponentOpened] = useState(false);
  const setComponent = setComponents
    ? (index: number, newComponent: TComponent | null) => {
        if (!setComponents) return;

        setComponents(
          produce(components, (draft) => {
            if (newComponent === null) {
              // Delete component if it is null
              draft.splice(index, 1);
            } else {
              draft[index] = newComponent;
            }
          })
        );
      }
    : undefined;
  const addComponent = (newComponent: TComponent) => {
    if (!setComponents) return;

    setComponents(
      produce(components, (draft) => {
        draft.push(newComponent);
      })
    );
  };
  const addComponentAtIndex = (index: number, newComponent: TComponent) => {
    if (!setComponents) return;

    setComponents(
      produce(components, (draft) => {
        draft.splice(index, 0, newComponent);
      })
    );
  };
  const getNewComponentType = (): string => {
    if (!parentComponent) {
      return "section";
    } else if (parentComponent.componentName === "section") {
      return "layout";
    } else {
      return "leaf";
    }
  };
  if (setComponents && components.length === 0) {
    return (
      <div
        className={
          "relative flex items-center justify-center hover:shadow-[inset_0_0_5px_2px_rgba(0,0,0,0.6)] min-h-16 " +
          styles.componentWrapper
        }
      >
        <AddComponentModal
          componentMap={componentMap}
          addComponent={addComponent}
          componentType={getNewComponentType()}
          opened={addComponentOpened}
          close={() => setAddComponentOpened(false)}
        />
        <div className={" hidden " + styles.editOverlay}>
          <PlusButton
            size="16px"
            onClick={() => setAddComponentOpened(true)}
            className=" border-2 rounded-full border-foreground/60 "
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      {components.map((component, index) => (
        <RenderComponentEdit
          componentMap={componentMap}
          key={index}
          component={component}
          setComponent={
            setComponent
              ? (newComponent: TComponent | null) =>
                  setComponent(index, newComponent)
              : undefined
          }
          addComponent={(newComponent: TComponent) => {
            addComponentAtIndex(index + 1, newComponent);
          }}
        />
      ))}
    </div>
  );
}
