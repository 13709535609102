import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/components/ui/alert-dialog";

const modalVariants = cva("", {
  variants: {
    size: {
      sm: "max-w-[500px]",
      md: "max-w-[700px]",
      lg: "max-w-[900px]",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export interface EditModalProps extends VariantProps<typeof modalVariants> {
  open?: boolean;
  setOpen: (open: boolean) => void;
  onSave: () => void;
  children?: React.ReactNode;
  title: string;
  disableSave?: boolean;
}

export function EditModal(props: EditModalProps) {
  const { open, setOpen, onSave, children, title, size, disableSave } = props;
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className={cn(modalVariants({ size }))}>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
        </AlertDialogHeader>
        {children}

        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setOpen(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={disableSave}
            className="bg-primary-foreground hover:bg-primary-foreground/80"
            onClick={() => {
              setOpen(false);
              onSave();
            }}
          >
            Save
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
