import { Link, useLocation } from "@remix-run/react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "~/components/ui/navigation-menu";
import { isPathActive } from "~/lib/utils";
import { NavigationItem } from "./types";

function MenuItemNoChild({ navItem }: { navItem: NavigationItem }) {
  const { label, url } = navItem;
  const location = useLocation();
  return (
    <Link
      to={url || "/"}
      className={`border-b-[3px] ${
        isPathActive(url, location.pathname)
          ? "border-b-primary-foreground"
          : "border-b-transparent"
      } hover:text-primary-foreground font-medium`}
    >
      {label}
    </Link>
  );
}

function MenuItem({ navItem }: { navItem: NavigationItem }) {
  const { label, children } = navItem;
  const location = useLocation();
  const isChildActive = children?.some((child) => {
    return isPathActive(child.url, location.pathname);
  });
  return (
    <>
      <NavigationMenuTrigger
        className={` p-0 font-medium hover:text-primary-foreground `}
      >
        <span
          className={`flex items-center border-b-[3px] h-[22px] ${
            isChildActive
              ? "border-b-primary-foreground"
              : "border-b-transparent"
          }`}
        >
          {label}{" "}
        </span>
      </NavigationMenuTrigger>
      <NavigationMenuContent className="flex flex-col gap-2 px-4 py-2 min-w-[100px]">
        {children?.map((child, index) => (
          <Link
            key={index}
            to={child.url || "/"}
            className={`${
              isPathActive(child.url, location.pathname)
                ? "text-primary-foreground"
                : ""
            } hover:text-primary-foreground font-medium`}
          >
            {child.label}
          </Link>
        ))}
      </NavigationMenuContent>
    </>
  );
}

export default function PrimaryEntry({ navItem }: { navItem: NavigationItem }) {
  return (
    <div>
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            {navItem.children?.length ? (
              <MenuItem navItem={navItem} />
            ) : (
              <MenuItemNoChild navItem={navItem} />
            )}
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
}
