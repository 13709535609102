import { produce } from "immer";
import { RenderComponents } from "~/features/pagebuilder";
import { TComponent, TComponentProps } from "~/features/pagebuilder/types";

type TDefaultLayoutData = {
  content: TComponent[];
  center?: boolean;
};
export function DefaultLayoutComponent(
  props: TComponentProps<TDefaultLayoutData>
) {
  const { component, setComponent, componentMap } = props;
  return (
    <div
      className={`px-1 py-1 ${component.data.center ? "mx-auto w-fit" : ""}`}
    >
      <RenderComponents
        components={component.data.content}
        componentMap={componentMap}
        setComponents={
          setComponent
            ? (newComponents: TComponent[]) =>
                setComponent(
                  produce(component, (draft) => {
                    draft.data.content = newComponents;
                  })
                )
            : undefined
        }
        parentComponent={component}
      />
    </div>
  );
}

DefaultLayoutComponent.defaultData = {
  content: [],
};
DefaultLayoutComponent.type = "layout";
