import { cn } from "~/lib/utils";
import { TIcon } from "./icons";

export const iconHeaderColorMap = new Map<string, string>();
iconHeaderColorMap.set(
  "bg-primary-foreground",
  "from-primary-foreground to-primary-foreground/50"
);
iconHeaderColorMap.set("bg-red-600", "from-red-800 to-red-400");
iconHeaderColorMap.set("bg-green-600", "from-green-800 to-green-400");
iconHeaderColorMap.set("bg-blue-600", "from-blue-800 to-blue-400");
iconHeaderColorMap.set("bg-orange-600", "from-orange-800 to-orange-400");
iconHeaderColorMap.set("bg-sky-600", "from-sky-800 to-sky-400");
iconHeaderColorMap.set("bg-cyan-600", "from-cyan-800 to-cyan-400");
export default function IconHeader({
  Icon,
  color,
  className,
  size,
}: {
  Icon: TIcon;
  color?: string;
  className?: string;
  size?: "sm" | "lg";
}) {
  return (
    <div
      className={cn(
        "flex items-center justify-center rounded-full bg-gradient-to-br shadow-md text-white",
        iconHeaderColorMap.get(color || "primary"),
        size === "sm" ? "size-8" : "size-10 md:size-14",
        className
      )}
    >
      <Icon
        className={cn(size === "sm" ? "size-5" : "size-7 md:size-9")}
        stroke={1.6}
      />
    </div>
  );
}
