"use client";

import { produce } from "immer";
import { useEffect, useState } from "react";
import ColorSwatch from "~/components/ui/color-swatch";

import { EditModal, TComponent } from "~/features/pagebuilder";
import { TSectionData, bgColorMap } from "./section-component";

export function EditSectionComponent(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TSectionData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [state, setState] = useState(component.data);

  useEffect(() => {
    // this is impportant, otherwise we will overwrite the component with old state on save
    setState(component.data);
  }, [component]);

  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      title="Edit Section"
      onSave={() => {
        setComponent(
          produce(component, (draft) => {
            draft.data = state;
          })
        );
      }}
    >
      Color:
      <div className="flex flex-wrap">
        {Array.from(bgColorMap.keys()).map((color) => (
          <ColorSwatch
            key={color}
            className={bgColorMap.get(color)}
            active={color === state.backgroundColor}
            onClick={() => {
              setState(
                produce(state, (draft) => {
                  draft.backgroundColor = color;
                })
              );
            }}
          />
        ))}
      </div>
    </EditModal>
  );
}
