import { TComponentProps } from "../../types";
import { EditFormComponentModal } from "./edit-form-component";
import { FormComponentClient } from "./form-component-client";

export type TFormComponentData = {
  form_uuid: string;
  token: string;
  center?: boolean;
};
export function FormComponent(props: TComponentProps<TFormComponentData>) {
  const { editOpened, setEditOpened, setComponent, component } = props;

  return (
    <>
      {setEditOpened ? (
        <EditFormComponentModal
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      {/* We need to render the form component in a separate client component,
       because the pagebuilder assumes everything is a RSC when rendering the page*/}
      <FormComponentClient
        token={component.data.token}
        center={component.data.center}
      />
    </>
  );
}

FormComponent.type = "leaf";
FormComponent.defaultData = {
  form_uuid: "",
  token: "",
};
