import { produce } from "immer";
import { useEffect, useState } from "react";
import { FileSelector, useFileSelector } from "~/components/file/file-selector";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { EditModal, TComponent } from "~/features/pagebuilder";
import { TImageComponentData } from "./image-component";

function getImageAspectRatio(url: string): Promise<number> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight;
      resolve(aspectRatio);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}

export function EditImageModal(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TImageComponentData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [src, setSrc] = useState(props.component.data.src);
  const [aspectRatio, setAspectRatio] = useState(
    props.component.data.aspectRatio
  );
  const [alt, setAlt] = useState(props.component.data.alt);
  const fileSelector = useFileSelector();
  useEffect(() => {
    setSrc(props.component.data.src);
    setAlt(props.component.data.alt);
    setAspectRatio(props.component.data.aspectRatio);
  }, [props.component, open]);

  useEffect(() => {
    if (fileSelector.selectedFile?.permanent_url) {
      setSrc(fileSelector.selectedFile.permanent_url);
    }
  }, [fileSelector.selectedFile]);
  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      onSave={() => {
        getImageAspectRatio(src).then((aspectRatio) => {
          setComponent(
            produce(component, (draft) => {
              draft.data.src = src;
              draft.data.alt = alt;
              draft.data.aspectRatio = aspectRatio;
            })
          );
        });
      }}
      title="Edit Image"
    >
      <FileSelector {...fileSelector} />
      <div className="flex flex-col gap-4">
        <img src={src} width={300} height={300} alt={alt} />
        <Button onClick={() => fileSelector.setOpen(true)} variant={"outline"}>
          Select Image
        </Button>
        <Input
          value={alt}
          onChange={(event) => setAlt(event.currentTarget.value)}
        />
      </div>
    </EditModal>
  );
}
