"use client";

import { useEffect, useState } from "react";
import { Checkbox } from "~/components/ui/checkbox";
import { FormRowDepricated } from "~/components/ui/form_old";
import { Input } from "~/components/ui/input";
import { BasicSelect } from "~/components/ui/select";
import { TComponent } from "../../types";
import { EditModal } from "../edit-modal";
import { TButtonComponentData } from "./button-component";

export function EditButtonModal(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TButtonComponentData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [state, setState] = useState(component.data);
  useEffect(() => {
    // this is impportant, otherwise we will overwrite the component with old state on save
    setState(component.data);
  }, [component]);
  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      title="Edit Button"
      onSave={() => {
        setComponent({
          ...component,
          data: state,
        });
      }}
    >
      <div>
        <FormRowDepricated
          label="Label"
          input={
            <Input
              value={state.label}
              onChange={(e) => setState({ ...state, label: e.target.value })}
            />
          }
        />
        <FormRowDepricated
          label="Align"
          input={
            <BasicSelect
              value={state.alignment}
              onChange={(value) =>
                setState({ ...state, alignment: value || state.alignment })
              }
              options={[
                { label: "Left", value: "left" },
                { label: "Center", value: "center" },
                { label: "Right", value: "right" },
              ]}
            />
          }
        />
        <FormRowDepricated
          label="Target URL"
          input={
            <Input
              value={state.href}
              onChange={(e) => setState({ ...state, href: e.target.value })}
            />
          }
        />
        <FormRowDepricated
          label="New window"
          input={
            <Checkbox
              checked={state.new_window}
              onCheckedChange={(checked) =>
                setState({ ...state, new_window: !!checked })
              }
            />
          }
        />
      </div>
    </EditModal>
  );
}
