import { JSONContent } from "@tiptap/react";
import { useMemo } from "react";
import { RenderTiptapContent } from "~/components/text-editor/content-renderer";
import { emptyTipTapContent } from "~/components/text-editor/tiptap-default-content";
import { TComponentProps } from "../../types";
import { EditSimpleCardComponent } from "./edit-simple-card-component";
import "./richtext.css";
import { pagebuilderRenderTypeMap } from "./tiptap-page-renderer";
export type TSimpleCardComponentData = {
  content: string;
};

export function SimpleCardComponent(
  props: TComponentProps<TSimpleCardComponentData>
) {
  const { editOpened, setEditOpened, setComponent, component } = props;
  const jsonContent = useMemo(() => {
    try {
      return JSON.parse(component.data.content);
    } catch (e) {
      return {} as JSONContent;
    }
  }, [component.data.content]);

  return (
    <>
      {setEditOpened ? (
        <EditSimpleCardComponent
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div className="rounded-lg border-border border shadow-md bg-card min-h-12 p-3 md:m-2">
        <div className="richTextContent smaller">
          <RenderTiptapContent
            content={jsonContent}
            renderTypeMap={pagebuilderRenderTypeMap}
          />
        </div>
      </div>
    </>
  );
}

SimpleCardComponent.type = "leaf";
SimpleCardComponent.defaultData = {
  content: JSON.stringify(emptyTipTapContent),
};
