"use client";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { FormRowDepricated } from "~/components/ui/form_old";
import { Input } from "~/components/ui/input";
import { getEmbedYoutubeUrl } from "~/lib/utils";
import { TComponent } from "../../types";
import { EditModal } from "../edit-modal";
import { TEmbedYoutubeData } from "./embed-youtube";

export function EditEmbedYoutubeModal(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TEmbedYoutubeData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [src, setSrc] = useState(props.component.data.src);
  const [title, setTitle] = useState(props.component.data.title);
  useEffect(() => {
    setSrc(props.component.data.src);
    setTitle(props.component.data.title);
  }, [props.component, open]);
  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      onSave={() => {
        setComponent(
          produce(component, (draft) => {
            draft.data.src = src;
            draft.data.title = title;
          })
        );
      }}
      title="Edit YouTube Video"
    >
      <FormRowDepricated
        label="YouTube Video URL"
        input={
          <Input
            value={src}
            onChange={(e) => setSrc(getEmbedYoutubeUrl(e.target.value))}
          />
        }
      />
    </EditModal>
  );
}
