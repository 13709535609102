import { BasicSelect } from "~/components/ui/select";
import { useGetFormList } from "../api/formbuilder-hooks";

export function FormSelector({
  selectedForm,
  setSelectedForm,
}: {
  selectedForm: string;
  setSelectedForm: (form: string) => void;
}) {
  const { data: forms } = useGetFormList();

  return (
    <>
      <BasicSelect
        options={
          forms?.objects.map((form) => {
            return { value: form.uuid, label: form.title };
          }) || []
        }
        value={selectedForm}
        onChange={(value) => {
          if (value) {
            setSelectedForm(value);
          }
        }}
        placeholder="Select a form"
      />
    </>
  );
}
