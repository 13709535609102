import { cn } from "~/lib/utils";

export interface ColorSwatchProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}
export default function ColorSwatch(props: ColorSwatchProps) {
  const { className, onClick, active } = props;
  return (
    <div
      onClick={onClick}
      className={cn(
        "w-10 h-10 rounded-full border mr-2 mb-2  cursor-pointer " +
          (active
            ? " border-4 border-foreground hover:border-foreground/80 "
            : " border border-foreground/40 hover:border-primary/80 "),
        className
      )}
    ></div>
  );
}
