import { Logo } from "~/components/logo";
import SocialsHeader from "../front-layout/socials-header";
import { AppLink } from "./applink";
import { MobileNavigation } from "./mobile-navigation";
import PrimaryEntry from "./primary-nav-entry";
import { NavigationProps } from "./types";
import { UserMenu } from "./usermenu";

function TopNavigationMobile({ navItems }: TopNavigationProps) {
  return (
    <>
      <div className="flex md:hidden mx-auto px-4 py-2 max-w-4xl justify-between items-center">
        <Logo className="h-[40px]" />
        <AppLink />
        <MobileNavigation navItems={navItems} cnTrigger="md:hidden" />
      </div>
    </>
  );
}

function TopNavigationDesktop({ navItems }: NavigationProps) {
  return (
    <div className="hidden md:flex mx-auto px-4 py-2 max-w-4xl justify-between items-center">
      <div className="flex space-x-4">
        <Logo className="h-[40px]" />

        <nav className="flex items-center space-x-2">
          {navItems.map((entry, index) => (
            <PrimaryEntry key={index} navItem={entry} />
          ))}
        </nav>
      </div>
      <div className="hidden md:flex items-center  space-x-4">
        <UserMenu compact={true} enterAppButton />
      </div>
    </div>
  );
}

interface TopNavigationProps extends NavigationProps {}

export function TopNavigation({ navItems }: TopNavigationProps) {
  return (
    <header className="bg-white border-b border-b-border">
      <SocialsHeader />
      <TopNavigationMobile navItems={navItems} />
      <TopNavigationDesktop navItems={navItems} />
    </header>
  );
}
