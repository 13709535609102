import { JSONContent } from "@tiptap/react";
import { useMemo } from "react";
import { RenderTiptapContent } from "~/components/text-editor/content-renderer";
import { emptyTipTapContent } from "~/components/text-editor/tiptap-default-content";
import IconHeader from "~/components/ui/icon-header";
import { iconMap } from "~/components/ui/icons";
import { cn } from "~/lib/utils";
import { TComponentProps } from "../../types";
import { EditFeatureCardComponent } from "./edit-feature-card-component";
import "./richtext.css";
import { pagebuilderRenderTypeMap } from "./tiptap-page-renderer";
export type TFeatureCardComponentData = {
  title: string;
  description: string;
  icon: string;
  iconColor: string;
};

export function FeatureCardComponent(
  props: TComponentProps<TFeatureCardComponentData>
) {
  const { editOpened, setEditOpened, setComponent, component } = props;
  const Icon = iconMap.get(component.data.icon);
  const jsonContent = useMemo(() => {
    try {
      return JSON.parse(component.data.description);
    } catch (e) {
      return {} as JSONContent;
    }
  }, [component.data.description]);

  return (
    <>
      {setEditOpened ? (
        <EditFeatureCardComponent
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div className="rounded-lg border-border border shadow-md bg-card min-h-12 p-3 my-2 md:m-2">
        <div className="flex mt-2">
          {Icon === undefined ? null : (
            <IconHeader
              Icon={Icon}
              color={component.data.iconColor}
              size="lg"
            />
          )}
        </div>
        <div className="mt-2 md:mt-4">
          <span className="text-xl font-bold"> {component.data.title}</span>
        </div>
        <div
          className={cn(
            "h-[3px] w-1/3  my-4 md:my-6",
            component.data.iconColor
          )}
        />
        <div className="richTextContent smaller">
          <RenderTiptapContent
            content={jsonContent}
            renderTypeMap={pagebuilderRenderTypeMap}
          />
        </div>
      </div>
    </>
  );
}

FeatureCardComponent.type = "leaf";
FeatureCardComponent.defaultData = {
  title: "Always up to date",
  description: JSON.stringify(emptyTipTapContent),
  icon: "calendar",
  iconColor: "bg-primary-foreground",
};
