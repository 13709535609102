import { produce } from "immer";
import { TComponent, TComponentProps } from "../../types";
import { RenderComponents } from "../render";
import { EditGridLayoutEqualCols } from "./edit-grid-layout-equal";

export type TGridLayoutEqualColsData = {
  col_count: number;
  cols: TComponent[][];
};

export function GridLayoutEqualColsComponent(
  props: TComponentProps<TGridLayoutEqualColsData>
) {
  const colSpanMap = [
    "md:col-span-12",
    "md:col-span-6",
    "md:col-span-4",
    "md:col-span-3",
    "md:col-span-2",
    "md:col-span-1",
  ];
  const { editOpened, setEditOpened, setComponent, component, componentMap } =
    props;

  return (
    <div className="grid grid-cols-1 px-1 md:grid-cols-12">
      {setEditOpened ? (
        <EditGridLayoutEqualCols
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      {Array.from({ length: component.data.col_count }).map((_, i) => (
        <div
          key={i}
          className={`py-1 ${colSpanMap[component.data.col_count - 1]} `}
        >
          <RenderComponents
            components={component.data.cols[i]}
            componentMap={componentMap}
            parentComponent={component}
            setComponents={
              setComponent
                ? (newComponents: TComponent[]) =>
                    setComponent(
                      produce(component, (draft) => {
                        draft.data.cols[i] = newComponents;
                      })
                    )
                : undefined
            }
          />
        </div>
      ))}
    </div>
  );
}

GridLayoutEqualColsComponent.defaultData = {
  col_count: 3,
  cols: [[], [], [], [], []],
};

GridLayoutEqualColsComponent.type = "layout";
