"use client";

import { useState } from "react";
import {
  DeleteButton,
  EditButton,
  IconButtonColor,
} from "~/components/ui/icon-button";
import { TComponent } from "../types";

import { PlusButton } from "~/components/ui/icon-button";
import { ConfirmationModal } from "~/components/ui/modals";
import { cn } from "~/lib/utils";
import styles from "./render.module.css";

const insetMap = new Map<string, string>();
insetMap.set("section", "shadow-[inset_0_0_5px_2px_rgba(0,0,200,1)]");
insetMap.set("layout", "shadow-[inset_0_0_5px_2px_rgba(0,200,0,1)]");
insetMap.set("leaf", "shadow-[inset_0_0_5px_2px_rgba(200,0,0,1)]");

const buttonColorMap = new Map<string, IconButtonColor>();
buttonColorMap.set("section", "blue");
buttonColorMap.set("layout", "green");
buttonColorMap.set("leaf", "red");

const borderMap = new Map<string, string>();
borderMap.set("section", "border-blue-500");
borderMap.set("layout", "border-green-500");
borderMap.set("leaf", "border-red-500");

const buttonPositionMap = new Map<string, string>();
buttonPositionMap.set("section", "right-0");
buttonPositionMap.set("layout", "right-0");
buttonPositionMap.set("leaf", "mx-auto left-0 right-0");

export function EditOverlay(props: {
  componentType: string;
  setAddComponentOpened: (open: boolean) => void;
  openEdit: () => void;
  setComponent: (component: TComponent | null) => void;
  component: TComponent;
}) {
  /* Renders the border marking editable components and also renders the edit buttons at the bottom */

  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    componentType,
    setAddComponentOpened,
    openEdit,
    component,
    setComponent,
  } = props;

  return (
    <>
      <div
        className={` hidden w-full h-full absolute top-0 left-0 pointer-events-none ${insetMap.get(
          componentType
        )} ${styles.editOverlay}`}
      >
        <div
          className={cn(
            " w-fit absolute bottom-[-12px] z-50 pointer-events-auto ",
            buttonPositionMap.get(componentType)
          )}
        >
          <ConfirmationModal
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onConfirm={() => {
              setComponent(null);
            }}
            description={"Are you sure you want to delete this component?"}
            okText="Delete"
          />
          <PlusButton
            size="16px"
            color={buttonColorMap.get(componentType)}
            onClick={() => setAddComponentOpened(true)}
            className={`border-2 rounded-full bg-white ${borderMap.get(
              componentType
            )}`}
          />
          <EditButton
            size="16px"
            color={buttonColorMap.get(componentType)}
            onClick={openEdit}
            className={`border-2 rounded-full bg-white ${borderMap.get(
              componentType
            )}`}
          />
          <DeleteButton
            size="16px"
            color={buttonColorMap.get(componentType)}
            onClick={() => setDeleteOpen(true)}
            className={`border-2 rounded-full bg-white ${borderMap.get(
              componentType
            )}`}
          />
        </div>
      </div>
    </>
  );
}
