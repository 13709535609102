import { TComponent, TComponentMap } from "../types";
import { RenderComponentsEdit } from "./render-edit";
import { RenderComponentsView } from "./render-view";

export function RenderComponents({
  components,
  setComponents,
  parentComponent,
  componentMap,
}: {
  components: TComponent[];
  setComponents?: (newComponents: TComponent[]) => void;
  parentComponent?: TComponent;
  componentMap: TComponentMap;
}) {
  return setComponents ? (
    <RenderComponentsEdit
      componentMap={componentMap}
      setComponents={setComponents}
      components={components}
      parentComponent={parentComponent}
    />
  ) : (
    <RenderComponentsView components={components} componentMap={componentMap} />
  );
}
