import { produce } from "immer";

import { RenderComponents } from "~/features/pagebuilder";
import { TComponent, TComponentProps } from "~/features/pagebuilder/types";
import { cn } from "~/lib/utils";
import { EditSectionComponent } from "./edit-section-component";

export type TSectionData = {
  content: TComponent[];
  backgroundColor?: string;
};
export const bgColorMap = new Map();
bgColorMap.set("white", "white");
bgColorMap.set("gray-light", "bg-gray-50");
bgColorMap.set("blue-light", "bg-blue-50");
bgColorMap.set("sky-light", "bg-sky-50");
bgColorMap.set("cyan-light", "bg-cyan-50");
bgColorMap.set("teal-light", "bg-teal-50");
bgColorMap.set("green-light", "bg-green-50");
bgColorMap.set("red-light", "bg-red-50");
bgColorMap.set("orange-light", "bg-orange-50");
bgColorMap.set("yellow-light", "bg-yellow-50");
bgColorMap.set("purple-light", "bg-purple-50");
bgColorMap.set("indigo-light", "bg-indigo-50");
bgColorMap.set("primary", "bg-primary");

export default function SectionComponent(props: TComponentProps<TSectionData>) {
  const { editOpened, setEditOpened, setComponent, component, componentMap } =
    props;
  return (
    <div
      className={`${
        component.data.backgroundColor
          ? bgColorMap.get(component.data.backgroundColor)
          : ""
      } min-h-16`}
    >
      {setEditOpened ? (
        <EditSectionComponent
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div className={cn("max-w-4xl mx-auto px-4 pt-2 pb-5")}>
        {
          <RenderComponents
            components={component.data.content}
            componentMap={componentMap}
            setComponents={
              setComponent
                ? (newComponents: TComponent[]) =>
                    setComponent(
                      produce(component, (draft) => {
                        draft.data.content = newComponents;
                      })
                    )
                : undefined
            }
            parentComponent={component}
          />
        }
      </div>
    </div>
  );
}
SectionComponent.defaultData = {
  content: [],
};
SectionComponent.type = "section";
