import {
  IconBrandInstagram,
  IconBrandYoutube,
  IconMail,
} from "@tabler/icons-react";
import { TIcon } from "~/components/ui/icons";

function SocialLink({
  label,
  href,
  icon: IconComponent,
}: {
  label: string;
  href: string;
  icon: TIcon;
}) {
  return (
    <div className="flex items-center">
      <IconComponent className="text-white size-4 mr-0.5" />
      <a
        href={href}
        className="text-white text-sm font-normal"
        target="_blank"
        rel="noreferrer"
      >
        {label}
      </a>
    </div>
  );
}

export function FacebookLink() {
  return (
    <SocialLink
      label="YouTube"
      href="https://www.youtube.com/@fizikasasonjom"
      icon={IconBrandYoutube}
    />
  );
}

export function InstagramLink() {
  return (
    <SocialLink
      label="Instagram"
      href="https://www.instagram.com/fizika.sa.sonjom/"
      icon={IconBrandInstagram}
    />
  );
}

export function MailLink() {
  return (
    <SocialLink
      label="Email"
      href="mailto:fizika@fizikasasonjom.com"
      icon={IconMail}
    />
  );
}

export default function SocialsHeader() {
  return (
    <div className="bg-primary-foreground rounded-b-xl max-w-4xl mx-auto flex items-center justify-around px-4 py-0.5">
      <FacebookLink />
      <MailLink />
      <InstagramLink />
    </div>
  );
}
