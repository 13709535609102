"use client";

import { JSONContent } from "@tiptap/react";
import { useEffect, useMemo, useState } from "react";
import TipTap from "~/components/text-editor/tiptap";
import { FormRowDepricated } from "~/components/ui/form_old";
import IconHeader, { iconHeaderColorMap } from "~/components/ui/icon-header";
import { iconMap } from "~/components/ui/icons";
import { Input } from "~/components/ui/input";
import { SelectIcon } from "~/components/ui/select-icon";
import { cn } from "~/lib/utils";
import { TComponent } from "../../types";
import { EditModal } from "../edit-modal";
import { TFeatureCardComponentData } from "./feature-card-component";

export function EditFeatureCardComponent(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TFeatureCardComponentData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [state, setState] = useState(component.data);
  useEffect(() => {
    // this is impportant, otherwise we will overwrite the component with old state on save
    setState(component.data);
  }, [component]);

  const jsonContent = useMemo(() => {
    try {
      return JSON.parse(state.description);
    } catch (e) {
      return {} as JSONContent;
    }
  }, [state.description]);

  if (!setComponent) {
    return null;
  }

  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      title="Edit Button"
      onSave={() => {
        setComponent({
          ...component,
          data: state,
        });
      }}
    >
      <div>
        <FormRowDepricated
          label="Icon"
          input={
            <SelectIcon
              value={state.icon}
              onChange={(value) => setState({ ...state, icon: value })}
            />
          }
        />

        <FormRowDepricated
          label="Color"
          input={
            <>
              {Array.from(iconHeaderColorMap.keys()).map((color) => {
                const Icon = iconMap.get(state.icon);
                if (!Icon) return null;
                return (
                  <div
                    key={color}
                    className="mr-1 cursor-pointer"
                    onClick={() => setState({ ...state, iconColor: color })}
                  >
                    <IconHeader
                      Icon={Icon}
                      color={color}
                      size="sm"
                      className={cn(
                        "opacity-60 hover:opacity-100",
                        color === state.iconColor && "opacity-100"
                      )}
                    />
                  </div>
                );
              })}
            </>
          }
        />

        <FormRowDepricated
          label="Title"
          input={
            <Input
              value={state.title}
              onChange={(e) => setState({ ...state, title: e.target.value })}
            />
          }
        />
        <TipTap
          content={jsonContent}
          setContent={(newJsonContent) =>
            setState({ ...state, description: JSON.stringify(newJsonContent) })
          }
          contentClassName="richTextContent smaller"
        />
      </div>
    </EditModal>
  );
}
