import { RenderTiptapContent } from "~/components/text-editor/content-renderer";
import { TComponentProps } from "~/features/pagebuilder";
import { EditRichTextModal } from "./edit-richtext-component";
import "./richtext.css";
import { pagebuilderRenderTypeMap } from "./tiptap-page-renderer";

export type TTextData = {
  content: string;
};

export function RichTextComponent(props: TComponentProps<TTextData>) {
  const { editOpened, setEditOpened, setComponent, component } = props;
  return (
    <>
      {setEditOpened ? (
        <EditRichTextModal
          open={editOpened}
          setOpen={setEditOpened}
          setComponent={setComponent}
          component={component}
        />
      ) : null}
      <div className="min-h-6 richTextContent">
        <RenderTiptapContent
          content={JSON.parse(component.data.content)}
          renderTypeMap={pagebuilderRenderTypeMap}
        />
      </div>
    </>
  );
}

RichTextComponent.type = "leaf";
RichTextComponent.defaultData = {
  content: "{}",
};
