"use client";

import { produce } from "immer";
import { useState } from "react";
import { Checkbox } from "~/components/ui/checkbox";
import { Form, FormField } from "~/components/ui/form_old";
import { useGetFormById } from "~/features/formbuilder/api/formbuilder-hooks";
import { FormSelector } from "~/features/formbuilder/components/form-selector";
import { TComponent } from "../../types";
import { EditModal } from "../edit-modal";
import { TFormComponentData } from "./form-component";

export function EditFormComponentModal(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TFormComponentData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [formUuid, setFormUuid] = useState(props.component.data.form_uuid);
  const [centered, setCentered] = useState(props.component.data.center);
  const { data: form } = useGetFormById(formUuid);
  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      onSave={() => {
        if (!form?.form_tokens[0].token) return;
        setComponent(
          produce(component, (draft) => {
            draft.data.form_uuid = formUuid;
            draft.data.token = form?.form_tokens[0].token;
            draft.data.center = centered;
          })
        );
      }}
      title="Edit Form"
      disableSave={
        !form || // form might be loading
        !formUuid || // nothing selected
        form?.uuid !== formUuid || // form might be loading
        !form?.form_tokens[0].token // no token available (should not happen)
      }
    >
      <Form>
        <FormField
          label="Form"
          input={
            <FormSelector
              selectedForm={formUuid}
              setSelectedForm={setFormUuid}
            />
          }
        />
        <FormField
          label="Center"
          input={
            <Checkbox
              checked={component.data.center}
              onCheckedChange={(checked) => setCentered(!!checked)}
            />
          }
        />
      </Form>
    </EditModal>
  );
}
