"use client";

import { produce } from "immer";
import { useEffect, useState } from "react";

import TipTap from "~/components/text-editor/tiptap";
import { EditModal, TComponent } from "~/features/pagebuilder";
import { TTextData } from "./richtext-component";
import "./richtext.css";

export function EditRichTextModal(props: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  setComponent?: (component: TComponent) => void;
  component: TComponent<TTextData>;
}) {
  const { open, setOpen, setComponent, component } = props;
  const [content, setContent] = useState(props.component.data.content);
  useEffect(() => {
    setContent(props.component.data.content);
  }, [props.component]);

  if (!setComponent) {
    return null;
  }
  return (
    <EditModal
      open={open}
      setOpen={setOpen}
      onSave={() => {
        setComponent(
          produce(component, (draft) => {
            draft.data.content = content;
          })
        );
      }}
      title="Edit Text"
      size={"lg"}
    >
      <TipTap
        content={JSON.parse(content)}
        setContent={(json_content) => setContent(JSON.stringify(json_content))}
        contentClassName="richTextContent"
      />
    </EditModal>
  );
}
