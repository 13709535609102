import { useGetNavOutlet } from "~/features/pagebuilder/api/navigation-hooks";
import { TopNavigation } from "../navigation";
import { Footer } from "./footer";

export function FrontLayout({ children }: { children?: React.ReactNode }) {
  const { data: navigationEntriesMain } = useGetNavOutlet("main");
  const { data: navigationEntriesFooter } = useGetNavOutlet("footer");

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <TopNavigation navItems={navigationEntriesMain?.objects || []} />
      <div className="flex-grow">{children}</div>
      <Footer navItems={navigationEntriesFooter?.objects || []} />
    </div>
  );
}
